import inViewport from '../../utils/inViewport'
import { currentDevice } from '../../core/utils/currentDevice'
import fastbooking from '../../shared/fastbooking/ibh_fastbooking'
import { hideHeaderStickyHotel } from '../../shared/header_sticky_hotel/header_sticky_hotel'

export function bindEvents($lightbox) {
  const $lightboxDom = $lightbox.get(0)

  // Add anchor to links inside lightbox
  $lightbox.find('.js-gallery-anchor').on('click', function (e) {
    e.preventDefault()
    const $parent = $lightbox.parent()
    const adjust_scroll = $('.js-gallery-header').height() + 16
    const destination = $(this).attr('href')
    const calculatedTop = $(destination).position().top - adjust_scroll
    $parent.scrollTo(calculatedTop, 200)
  })

  // Set anchor link as active based on visible section
  $lightboxDom.querySelectorAll('.js-gallery-section').forEach(section => {
    inViewport(
      section,
      element => {
        if (element.isIntersecting) {
          // Remove active class from old active menu item
          $lightboxDom.querySelector(`.js-menu-item-in-bar.is-active`)?.classList.remove('is-active')

          // Add active class to current menu item
          $lightboxDom
            .querySelector(`.js-gallery-anchor[href="#${section.id}"]`)
            ?.closest('.menu_item')
            ?.classList.add('is-active')
        }
      },
      {
        threshold: 0.5,
      }
    )
  })

  // Add custom fancybox for fullscreen behaviour

  $lightbox.find('[data-fancybox]').fancybox({
    animationEffect: 'fade',
    arrows: true,
    baseClass: 'hotel-gallery-fullscreen',
    buttons: ['close'],
    infobar: false,
    touch: {
      vertical: true,
      momentum: true,
    },
    wheel: false,
    onInit() {
      const fancyboxFooter = document.querySelector('.js-gallery-image-footer')
      const navigationPrevFullscreen = document.querySelector('.js-hotel-gallery-navigation-prev')
      const navigationNextFullscreen = document.querySelector('.js-hotel-gallery-navigation-next')
      const fancybox = document.querySelector('.hotel-gallery-fullscreen .fancybox-inner')
      const ctasBookNow = fancyboxFooter.querySelectorAll('.js-btn-floating-fastbooking')
      const imageWrapper = document.querySelectorAll('.hotel-gallery__image-wrap')
      let isVertical

      fancybox.append(fancyboxFooter)
      fancybox.append(navigationPrevFullscreen, navigationNextFullscreen)
      ctasBookNow.forEach(ctaBookNow => {
        ctaBookNow.addEventListener('click', ()=> {
          $.fancybox.close()
          /**We need this click to close the first fancybox */
          document.querySelector('.js-btn-close-fancybox').click()
          fastbooking.openStickyFastbooking()

          if (!currentDevice.isMobile) {
            hideHeaderStickyHotel()
            fastbooking.openDates()
          }

        })
      })
      imageWrapper.forEach(image => {
        if (image.dataset.isVertical === 'is-vertical') {
          isVertical = true
          return isVertical
        }
      })
    },
    beforeShow(instance, current) {
      const footerCaption = document.querySelectorAll(
        '.hotel-gallery-fullscreen .fancybox-inner .hotel-gallery-image-footer .hotel-gallery-image-caption'
      )

      footerCaption.forEach(caption => (caption.innerHTML = current.opts.caption))
    },
    afterShow(instance, current) {
      const fancyboxContent = document.querySelectorAll('.hotel-gallery-fullscreen .fancybox-inner .fancybox-content')

      fancyboxContent.forEach(content => {
        content.classList.add('fullscreen-image')
        if (current.opts.$orig[0].classList.contains('is-vertical')) {
          content.classList.add('is-vertical')
        } else {
          content.classList.remove('is-vertical')
        }
      })
    },
  })

  // Show video iframe and hide video picture block
  const videoContainer = document.querySelector('.js-hotel-gallery-video-container')
  videoContainer?.querySelectorAll('.js-hotel-gallery-video').forEach(video => {
    const videoPicture = video.querySelector('.js-hotel-gallery-video-image')
    const videoIframe = video.querySelector('.js-hotel-gallery-video-iframe')
    videoPicture.addEventListener('click', () => {
      videoPicture.classList.add('hidden')
      videoIframe.classList.remove('hidden')
    })
  })
}

const getOffsetTop = element => {
  if (!element) return 0
  return getOffsetTop(element.offsetParent) + element.offsetTop
}

export function scrollTo($lightbox, destinationSelector) {
  const destinationElement = document.querySelector(destinationSelector)

  if (!destinationElement) return

  const offsetTop = getOffsetTop(destinationElement)
  const adjust_scroll = document.querySelector('.js-gallery-header').offsetHeight
  const calculatedTop = offsetTop - adjust_scroll
  $lightbox.parent().scrollTo(calculatedTop)
}

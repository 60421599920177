import { getPageVariation } from '../../core/analytics/shared.analytics'
import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'
import { search_action_type } from '../../utils/utilities'

import { UtagEvents } from './constants'
import fastbooking from './ibh_fastbooking'

export function isDestiniaForm() {
  return fastbooking.getTab() !== 'hotel'
}

export function isFhiPage() {
  return document.querySelector('html').dataset.fhiTest !== undefined
}
export function isFhPage() {
  return document.querySelector('html').dataset.pageTyoe === 'hotel'
}

// DONE
export function initializeFastbookingTabUtagEvent() {
  /* utag-event for flight and hotel tab */
  document.addEventListener(UtagEvents.FlightHotelTab, function () {
    sendUtagEvent({
      data: {
        event_name: 'searcher_select',
        event_cat: 'searcher',
        event_act: 'flight and hotel',
        event_lbl: '',
        event_purpose: 'booking',
        event_structure: fastbooking.isSticky ? 'sticky' : 'body',
      },
    })
  })

  /* utag-event for hotel tab */
  document.addEventListener(UtagEvents.HotelTab, function () {
    sendUtagEvent({
      data: {
        event_name: 'searcher_select',
        event_cat: 'searcher',
        event_act: 'hotel',
        event_lbl: '',
        event_purpose: 'booking',
        event_structure: fastbooking.isSticky ? 'sticky' : 'body',
      },
    })
  })
}
export function initializeUtagEvents() {
  let event_name, event_cat, event_act, event_lbl, event_purpose, event_structure, page_variation
  const resource_class = document.querySelector('body').dataset.utagResourceClass
  const fhiPageType = document.querySelector('html').dataset.fhiTest

  /* Destination (place to go) input events */
  document.addEventListener(UtagEvents.Destination, event => {
    const data = event.detail
    let type = data.type
    let title = data.value.title_en
    if (data.type === 'lastSearches') {
      type = 'see last search'
    }
    if (data.type === 'airport') {
      type = 'origin'
      title = data.value.title
    }

    if (data.type === 'fhdestination') {
      type = 'destination'
      title = data.value.utag_title
    }
    event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    event_act = type
    event_lbl = title
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  // TODO: PY06114 Fase 2
  /* Airports (destinia) input events */
  document.querySelector('.utag-departure-airport')?.addEventListener('click', () => {
    event_name = 'searcher_flight_hotel'
    event_cat = 'searcher fly'
    event_act = 'origin'
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  /* People input events */
  document.addEventListener(UtagEvents.Occupancy, function () {
    const event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    const event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    const event_purpose = 'booking'
    const event_structure = fastbooking.isSticky ? 'sticky' : 'body'

    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act: 'rooms',
        event_lbl: '',
        event_purpose,
        event_structure,
      },
    })
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act: 'guests',
        event_lbl: '',
        event_purpose,
        event_structure,
      },
    })
  })

  document.addEventListener(UtagEvents.AddMoreRooms, function () {
    if (isDestiniaForm()) return
    const event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    const event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    const event_purpose = 'booking'
    const event_structure = fastbooking.isSticky ? 'sticky' : 'body'

    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act: 'more than 3 rooms',
        event_lbl: '',
        event_purpose,
        event_structure,
      },
    })
  })

  /* Confirm people and rooms button events */
  document.addEventListener(UtagEvents.ConfirmOccupancy, () => {
    event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    event_act = 'guests'
    event_lbl = ``
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  /* Calendar events */
  document.addEventListener(UtagEvents.Dates, function () {
    IB.VisitorSessionId.initNewVisitorSessionId()
    const event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    const event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    const event_act = 'dates'
    const event_purpose = 'booking'
    const event_structure = 'body'
    const page_variation = getPageVariation()
    const event_lbl = ''
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  document.addEventListener(UtagEvents.FlexibleDates, () => {
    event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    event_act = 'flexible date'
    event_lbl = 'see calendar'
    event_structure = 'body'
    event_purpose = 'booking'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })
  /* Discounts events */
  document.addEventListener(UtagEvents.Promocode, event => {
    const data = event.detail
    event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    event_act = 'promotion code'
    event_purpose = 'booking'
    event_lbl = data?.promocode
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act: 'discount',
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  document.addEventListener(UtagEvents.PromocodeFocused, e => {
    const data = e.detail
    event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
    event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    event_act = 'promotion code'
    event_purpose = 'booking'
    event_lbl = ''
    event_structure = 'body'
    page_variation = getPageVariation()

    event_lbl = data.promocode

    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act: 'promotion code',
        event_lbl: '',
        event_purpose: 'booking',
        event_structure: 'body',
        page_variation,
      },
    })
  })
  /* Scroll to fastbooking events */
  document
    .querySelectorAll(
      '.utag-mobile-cta-sticky-bar .utag-open-mobile-panel, .utag-mobile-cta-sticky-bar .utag-open-search-btn'
    )
    .forEach(element => {
      element.addEventListener(
        'click',
        () => {
          event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
          event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
          event_act = 'scroll to fastbooking'
          event_purpose = 'booking'
          event_lbl = 'sticky'
          event_structure = 'sticky'
          page_variation = getPageVariation()
          sendUtagEvent({
            data: {
              event_name,
              event_cat,
              event_act,
              event_lbl,
              event_purpose,
              event_structure,
            },
          })
        },
        { once: true }
      )
    })

  document.querySelectorAll('.utag-button-scroll-to').forEach(element => {
    element.addEventListener(
      'click',
      () => {
        event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
        event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
        event_act = 'scroll to fastbooking'
        event_purpose = 'boooking'
        event_lbl = 'body'
        event_structure = 'body'
        page_variation = getPageVariation()
        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            event_act,
            event_lbl,
            event_purpose,
            event_structure,
          },
        })
      },
      { once: true }
    )
  })

  document.querySelector('.utag-fastbooking-gallery')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  document.querySelector('.utag-floating-fastbooking')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  /** Submit */

  document.addEventListener(UtagEvents.Submit, () => {
    event_name = isDestiniaForm() ? 'searcher_flight_hotel_submit' : 'searcher_hotel_submit'
    event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
    event_act = 'submit'
    event_purpose = 'booking'
    event_structure = 'body'
    event_lbl = 'search'

    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        ...(isFhiPage ? { page_variation: fhiPageType } : {}),
      },
    })
  })

  // TODO: PY0503 quitar JQuery

  /* not in the bible */

  /** Estas donte estan? MICE? */
  $(document).one('click', '.fastbooking-links a.see-more-hotels-fancy', function () {
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = 'see hotel list'
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  document.querySelector('.utag-three-more-rooms')?.addEventListener('click', function () {
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = 'more than 3 rooms'
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  /* Evento para el selector múltiple del FB en MICE y meetings*/
  let mice_place_value = [] // esto no se para que es
  $(document).on('click', '.place-to-go-mice:visible', function () {
    mice_place_value = $('#vo_meetings_search_destination_id').val()
  })

  $(document).one('change', '#vo_meetings_search_destination_id', function () {
    const place_value = $.merge(
      $(mice_place_value).not($(this).val()).get(),
      $($(this).val()).not(mice_place_value).get()
    ).slice(-1)[0]
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = search_action_type(place_value)
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  $(document).one('selector_change', '.place-to-go-mice .counter', function () {
    const place_value = $('.place-to-go-mice .chosen-choices .search-choice span').first().text()
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = search_action_type(place_value)
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  // Evento para el warning de Vuelo + Hotel
  on({
    eventName: 'click',
    selector: `.js-accept-button`,
    handler() {
      event_name = 'searcher_select'
      event_cat = 'searcher'
      event_act = 'flight and hotel'
      event_lbl = 'confirmation'
      event_structure = 'popup'
      event_purpose = 'booking'

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_structure,
          event_purpose,
        },
      })
    },
  })

  on({
    eventName: 'click',
    selector: `.js-reject-button`,
    handler() {
      event_name = 'searcher_select'
      event_cat = 'searcher'
      event_act = 'flight and hotel'
      event_lbl = 'disagreement'
      event_structure = 'popup'
      event_purpose = 'booking'

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_structure,
          event_purpose,
        },
      })
    },
  })
}

export function fastbookingTabsEvents(tab) {
  sendUtagEvent({
    data: {
      event_name: 'searcher_select',
      event_cat: 'searcher',
      event_act: tab,
      event_lbl: '',
      event_purpose: 'booking',
      event_structure: fastbooking.isSticky ? 'sticky' : 'body',
    },
  })
}

export function flightAndHotelModalEvents(action) {
  sendUtagEvent({
    data: {
      event_name: 'searcher_select',
      event_cat: 'searcher',
      event_act: 'flight and hotel',
      event_lbl: action,
      event_purpose: 'booking',
      event_structure: 'popup',
    },
  })
}
